import callApi from '../../helpers/callApi';

export default {
  login(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      url: '/auth',
      method: 'post',
      data: params,
    });
  },
};
