import classnames from 'classnames';
import sha256 from 'crypto-js/sha256';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import apiAuth from '../../api/auth';
import apiUser from '../../api/user';
import { introBgImg } from '../../constants/images';
import styles from './IntroStyle.module.scss';

@withRouter
@inject('modalStore')
@inject('authStore')
@inject('branchStore')
@observer
class LoginSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idWarning: false,
      passwordWarning: false,
      idWarningMessage: null,
      passwordWarningMessage: null,
    };
  }

  loginFunction = async (event) => {
    event.preventDefault();

    if (this.refs.id.value.trim().length === 0) {
      this.setState({
        idWarning: true,
        idWarningMessage: '아이디와 비밀번호를 입력해주세요',
        passwordWarning: false,
        passwordWarningMessage: '',
      });

      return;
    }

    if (this.refs.password.value.trim().length === 0) {
      this.setState({
        idWarning: false,
        idWarningMessage: '',
        passwordWarning: true,
        passwordWarningMessage: '아이디와 비밀번호를 입력해주세요',
      });

      return;
    }

    const data = {
      disableErrorHandler: true,
      username: this.refs.id.value,
      password: sha256(this.refs.password.value).toString(),
    };

    try {
      let response = await apiAuth.login(data).then(async (res) => {
        this.props.branchStore.prefetch(res.data.access_token);

        return res;
      });
      let responseData = response.data;

      if (response.status === 200) {
        await this.props.authStore.login(responseData.access_token);
        const realname = this.props.authStore.realname;

        if (!responseData.enabled) {
          return this.props.router.replace(`/onBoarding?name=${encodeURIComponent(realname)}`);
        }

        return this.props.router.replace('/home');
      }

      switch (responseData.error_no) {
        case 101:
          alert(responseData.error.data);
          break;
        case 102:
          this.setState({
            idWarning: false,
            idWarningMessage: '',
            passwordWarning: true,
            passwordWarningMessage:
              '입력하신 정보와 일치하는 계정이 없습니다. 로그인 정보를 확인해주세요.',
          });
          break;
        case 103:
          this.setState({
            idWarning: false,
            idWarningMessage: '',
            passwordWarning: true,
            passwordWarningMessage: '아이디 또는 비밀번호가 일치하지 않습니다.',
          });
          break;
        default:
          this.setState({
            idWarning: false,
            idWarningMessage: '',
            passwordWarning: true,
            passwordWarningMessage: '로그인에 실패하였습니다.',
          });
      }
    } catch (error) {
      console.log(error);
      alert('로그인에 실패하였습니다.');
    }
  };

  logoutFunction = async () => {
    this.props.authStore.logout();
  };

  goRegisterUserPage = () => {
    this.props.modalStore.modalClose();
    this.props.router.push('/registerUser');
  };

  _showRegisterIntroModal = () => {
    const registerIntroModal = (
      <div className={styles.register_user_intro_modal_content_wrap}>
        <div className={styles.box_wrap}>
          <div className={styles.content_box}>
            <h1>대표자</h1>
            <p className={styles.mainSentence}>IMS.form에 가입하신 적이 없으시다면</p>
            <p>회원가입을 진행해주세요.</p>
            <p className={styles.subSentence} style={{ paddingTop: '42px' }}>
              ※업체 직원을 등록하고자 하신다면 회원가입 후
            </p>
            <p className={styles.subSentence}>
              <span style={{ color: '#ffa723' }}>{`직원관리>직원등록`}</span>으로 진행해주셔야
              합니다.
            </p>
          </div>
          <div className={styles.button} onClick={this.goRegisterUserPage}>
            {MESSAGE.SIGNING_UP}
          </div>
        </div>
        <div className={styles.box_wrap}>
          <div className={styles.content_box}>
            <h1>직원</h1>
            <p className={styles.mainSentence}>이미 가입되어 있는 업체의 직원일 경우</p>
            <p>대표자 아이디로 로그인 후</p>
            <p>
              <span style={{ color: '#ff0000' }}>직원 관리</span>를 통하여 가입해 주세요.
            </p>
            <p className={styles.subSentence} style={{ paddingTop: '20px' }}>
              ※대표자가 회원이 아니라면 대표자 가입 진행 후
            </p>
            <p className={styles.subSentence}>직원 등록을 진행해주세요.</p>
          </div>
          <div className={styles.button} onClick={this.props.modalStore.modalClose}>
            메인으로
          </div>
        </div>
      </div>
    );
    const data = {
      modalComponent: registerIntroModal,
      modalTitle: '귀하의 해당하는 직함을 선택해주세요.',
      modalStyle: { width: '750px' },
    };

    this.props.modalStore.modalOpen(data);
  };

  defaultId = () => {
    // NEXT: state 부분 get param으로 변경해야됨
    return (this.props.router && this.props.router.query.username) || '';
  };

  moveFindAccountPage = (type) => {
    this.props.router.push(`/findAccount?tab=${type}`, '/findAccount');
  };

  render() {
    return (
      <section className={styles.login_section} style={{ backgroundImage: `url(${introBgImg})` }}>
        <div className={styles.login_container}>
          <div className={styles.login_area}>
            <div className={styles.login_title_area}>
              <div className={classnames([styles.login_title, 'wow fadeInUp'])}>
                당신의 완벽한 사업 파트너,
              </div>
              <div
                className={classnames([styles.login_title, 'wow fadeInUp'])}
                data-wow-delay="0.25s"
              >
                IMS form
              </div>
            </div>
            <div className={classnames([styles.login_box, 'wow fadeIn'])}>
              <form className={styles.loginForm} onSubmit={this.loginFunction}>
                <div className={styles.loginInputContainer}>
                  <input
                    className={styles.loginInput}
                    type="text"
                    ref="id"
                    placeholder="아이디"
                    defaultValue={this.defaultId()}
                  />
                  {this.state.idWarning && (
                    <div className={styles.warningMessage}>{this.state.idWarningMessage}</div>
                  )}
                </div>

                <div className={styles.loginInputContainer}>
                  <input
                    className={styles.loginInput}
                    type="password"
                    ref="password"
                    placeholder="비밀번호"
                  />
                  {this.state.passwordWarning && (
                    <div className={styles.warningMessage}>{this.state.passwordWarningMessage}</div>
                  )}
                </div>
                <button className={styles.loginButton}>{MESSAGE.LOG_IN}</button>
              </form>
              <button
                className={styles.joinButton}
                onClick={() => this.props.router.push('/registerIntro')}
              >
                {MESSAGE.JOIN_MEMBER}
              </button>
              <div className={styles.loginLinkDiv}>
                <div
                  className={styles.loginLinkText}
                  onClick={() => this.moveFindAccountPage('id')}
                >
                  {MESSAGE.FIND_ID}
                </div>
                <div className={styles.betweentext} />
                <div
                  className={styles.loginLinkText}
                  onClick={() => this.moveFindAccountPage('password')}
                >
                  {MESSAGE.FIND_PASSWORD}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LoginSection;
